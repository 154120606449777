
<template>
    <div>
        <v-container>

        </v-container>
    </div>
</template>
<script>
  export default {
    computed: {
      navigation_links() {
          return [
            
                // {
                //     path : '/admins-list',
                //     title : 'admins',
                // },
                {
                    path : '/sections-list',
                    title : 'sections',
                },
                {
                    path : '/items-list',
                    title : 'items',
                },
        
                {
                    path : '/clients-list',
                    title : 'clients',
                },
        
                {
                    path : '/display_text-list',
                    title : 'display_text',
                },
        
                
        
                
        
                // {
                //     path : '/site_forms-list',
                //     title : 'site_forms',
                // },
        
                // {
                //     path : '/team_members-list',
                //     title : 'team_members',
                // },
        
        ]
      },
    },
  }
</script>